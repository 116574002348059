/* body,
html {
  margin: 10 10 10 10 !important;
  padding: 0 !important;
  height:500px
} */
.Pano {
  font-family: sans-serif;
  text-align: center;
  background-color: white;
  /* position: relative; */
  /* width: 100px;
  height: 200px;  */
  /* overflow-y: scroll;
  margin: 10 !important;
  padding: 10 !important;  */
}
